@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: Poppins-Medium;
  src: url(../fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: Poppins-Bold;
  src: url(../fonts/Poppins-Bold.ttf);
}
@font-face {
  font-family: Poppins-SemiBold;
  src: url(../fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: Montserrat-Bold;
  src: url(../fonts/Montserrat-Bold.ttf);
}

.txt1 {
  font-family: Poppins-Regular;
  font-size: 13px;
  line-height: 1.4;
  color: #999;
}
.txt2 {
  font-family: Poppins-Regular;
  font-size: 13px;
  line-height: 1.4;
  color: #00ad5f;
}
.txt3 {
  font-family: Poppins-Regular;
  font-size: 15px;
  line-height: 1.4;
  color: #00ad5f;
  text-transform: uppercase;
}
.size1 {
  width: 355px;
  max-width: 100%;
}
.size2 {
  width: calc(100% - 43px);
}
.limiter {
  width: 100%;
  margin: 0 auto;
}
.container-login100 {
  font-family: Poppins-Regular, sans-serif;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f2f2f2;
}
.wrap-login100 {
  width: 585px;
  background: #fff;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row-reverse;
}
.login100-more {
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}
.login100-more::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}
.login100-form {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  padding: 103px 65px 40px;
}
.login-logo {
  padding: 103px 0px 0px;
  margin: auto;
}
.login100-form-title {
  font-family: Poppins-Regular;
  font-size: 20px;
  color: #555;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  display: block;
}
.wrap-input100 {
  width: 100%;
  position: relative;
  border: 1px solid #e6e6e6;
}
.rs1-wrap-input100,
.rs2-wrap-input100 {
  width: 50%;
}
.rs2-wrap-input100 {
  border-left: none;
}
.input100 {
  display: block;
  width: 100%;
  background: 0 0;
  font-family: Poppins-Regular;
  font-size: 18px;
  color: #666;
  line-height: 1.2;
  padding: 0 25px;
  border-radius: 0;
}
input.input100 {
  height: 55px;
}
.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #00ad5f;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}
.input100:focus + .focus-input100 {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  background: #00ad5f;
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.login100-form-btn:hover {
  background: #333;
}
@media (max-width: 992px) {
  .login100-form {
    width: 60%;
    padding-left: 30px;
    padding-right: 30px;
  }
  .login100-more {
    width: 40%;
  }
}
@media (max-width: 768px) {
  .login100-form {
    width: 100%;
  }
  .login100-more {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .login100-form {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 150px;
  }
  .rs1-wrap-input100,
  .rs2-wrap-input100 {
    width: 100%;
  }
  .rs2-wrap-input100 {
    border-left: 1px solid #e6e6e6;
  }
}
.validate-input {
  position: relative;
}
.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
  font-family: Poppins-Regular;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.alert-validate::after {
  content: "\f12a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 18px;
}
.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

.p-b-34 {
  padding-bottom: 34px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.w-full {
  width: 100%;
}
.text-center {
  text-align: center;
}
.p-t-27 {
  padding-top: 27px;
}
.p-b-239 {
  padding-bottom: 239px;
}
@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
  .login100-form,
  .login-logo {
    padding-top: 50px;
  }
}
