@import url("https://fonts.googleapis.com/css?family=Roboto:wght@100;40");
@import url("https://fonts.googleapis.com/css?family=Lato");

.App {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  text-align: center;
  max-width: 100vw;
  margin: 0;
}
.App-main {
  position: relative;
  background-color: #eee;
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  margin: 0;
  border: 0;
  border-radius: 0;
}
.App-body {
  width: 100vw;
  max-width: 100vw;
  overflow-x: auto;
  overflow-y: auto;
  height: 99%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* GENERIC TAGS */
h1,
h2,
h3,
p,
input {
  color: #4a4a4a;
}
input {
  font-family: "Lato", sans-serif;
  font-size: 12pt;
  padding: 10px;
  border-radius: 10px;
  border: solid 1px #ccc;
}
select {
  font-family: "Lato", sans-serif;
  font-size: 12pt;
  padding: 10px;
  border-radius: 10px;
  border: solid 1px #ccc;
  width: 100%;
  -webkit-appearance: initial;
}
textarea {
  font-family: "Lato", sans-serif;
  font-size: 12pt;
  padding: 10px;
  border-radius: 10px;
  border: solid 1px #ccc;
  resize: none;
}
button {
  font-family: "Lato", sans-serif;
  font-size: 12pt;
  padding: 8px 16px;
  border-radius: 6px;
  border: solid 1px #ccc;
  background: #67a3a9;
  color: #fff;
  cursor: pointer;
}
a {
  font-weight: bold;
  color: green;
  text-decoration: none;
}

/* GENERIC CLASSES */
.bg-lyfsupport {
  background-color: #004950 !important;
  /* border-radius: 20% !important; */
}
.text-lyfsupport {
  color: #004950;
}
.hide {
  display: none;
}
.error {
  background: #fee;
  border: solid 1px #f00;
  padding: 10px;
  border-radius: 4px;
  color: #f00;
}

/* TOP PANEL */
.controlPanel {
  padding: 12px;

  background-color: #f9f9f9;
  border-bottom: solid 1px #ccc;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 42px;
  z-index: 1000;
}

/* TOP PANEL - CURRENT USER */
.currentUser {
  float: left;
  overflow: auto;
}
.currentUser .avatarWrapper {
  width: 44px;
  height: 44px;
  float: left;
  clear: all;
  position: relative;
}
.currentUser .avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #999;
  background-size: cover;
}
.currentUser .info {
  text-align: left;
  margin-left: 50px;
  margin-top: 3px;
}
.currentUser h1 {
  font-size: 14px;
  margin: 0px;
  padding: 0px;
}
.currentUser p {
  margin: 0px;
}
.currentUser a {
  font-size: 14px;
}
.currentUser .offline {
  width: 14px;
  height: 14px;
  border: solid 3px #f9f9f9;
  border-radius: 18px;
  background: #ccc;
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.currentUser .online {
  width: 14px;
  height: 14px;
  border: solid 3px #f9f9f9;
  border-radius: 18px;
  background: green;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

/* TOP PANEL - STATUS BUTTON */
.statusWrapper,
.statusWrapper a,
.switchWrapper,
.switchWrapper p,
.switchWrapper h4 {
  font-size: 14px;
  text-align: left;
}

.entryRow {
  padding-top: 10px;
}

/* TOP PANEL - SWITCH */
.switchWrapper .offline {
  color: #666;
}
.switchWrapper .online {
  color: green;
}

/* THREADS */
.threadQueue {
  width: 100%;
  background: #ecfbf7;
  border-right: solid 1px #666;
  overflow-y: scroll;
}
.threadQueue h4 {
  margin: 0px 10px;
  padding: 10px;
  border-bottom: solid 1px #ccc;
}

.threadList {
  width: 100%;
  height: 200px;
  background: #ecfbf7;
  border-right: solid 1px #666;
  overflow-y: scroll;
}
.threadList h4 {
  margin: 0px 10px;
  padding: 10px;
  border-bottom: solid 1px #ccc;
}

.otherThreadList {
  width: 100%;
  height: 200px;
  background: #ecfbf7;
  border-right: solid 1px #666;
  overflow-y: scroll;
}
.otherThreadList h4 {
  margin: 0px 10px;
  padding: 10px;
  border-bottom: solid 1px #ccc;
}

.threadCell {
  height: 70px;
  margin: 6px;
  border: solid 2px #ecfbf7;
  cursor: pointer;
  position: relative;
}
.threadCell.selected {
  margin: 6px;
  border: solid 2px #4ac4a2;
  background-color: rgba(0, 128, 0, 0.1);
}
.threadCell .avatar {
  position: absolute;
  left: 10px;
  top: 8px;
}

.threadCell .menu {
  position: absolute;
  right: 5px;
  top: 32px;
  color: #4ac4a2;
}
.threadCell .username {
  position: absolute;
  height: 25px;
  top: 10px;
  border-radius: 21px;
  left: 60px;
  right: 10px;
  font-weight: bold;
  margin: 0px;
  text-align: left;
}
.threadCell .message {
  position: absolute;
  max-width: calc(100% - 75px);
  height: 20px;
  min-width: 100px;
  top: 30px;
  left: 60px;
  right: 10px;
  margin: 0px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* STAFF */
.staffList {
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background: #ecfbf7;
  border-right: solid 1px #666;
  overflow-y: scroll;
}
.staffList h4 {
  margin: 0px 10px;
  padding: 10px;
  border-bottom: solid 1px #ccc;
  margin-bottom: 10px;
}
.staffCell {
  overflow: auto;
  padding: 17px;
  padding-top: 0px;
}
.staffCell .avatarWrapper {
  width: 44px;
  height: 44px;
  float: left;
  clear: all;
  position: relative;
}
.staffCell .avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: solid 1px #ccc;
  background-color: #999;
  background-size: cover;
}
.staffCell .info {
  text-align: left;
  margin-left: 50px;
  margin-top: 4px;
}
.staffCell h1 {
  font-size: 14px;
  margin: 0px;
  padding: 0px;
}
.staffCell p {
  margin: 0px;
  font-size: 12px;
}
.staffCell .offline {
  width: 14px;
  height: 14px;
  border: solid 3px #ecfbf7;
  border-radius: 18px;
  background: #ccc;
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.staffCell .online {
  width: 14px;
  height: 14px;
  border: solid 3px #ecfbf7;
  border-radius: 18px;
  background: green;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

/* PREVIOUS */
.threadPrevious {
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background: #ecfbf7;
  border-right: solid 1px #666;
  overflow-y: scroll;
}
.threadPrevious h4 {
  margin: 0px 10px;
  padding: 10px;
  border-bottom: solid 1px #ccc;
  margin-bottom: 10px;
}
.threadPreviousNext {
  padding: 20px 0px;
}
.threadPreviousLoader {
  padding: 20px 0px;
}

/* MESSAGES */
.chatContainer {
  position: relative;
  min-width: 100%;
  display: inline-block;
}
.chatCell {
  padding: 2px 4px;
  margin-left: 12px;
  min-width: 40px;
  max-width: 80%;
  background-color: #ededed;
  border-radius: 18px;
  margin-bottom: 8px;
  display: block;
  position: relative;
  float: left;
  margin-right: 10%;
  line-height: 100%;
}
.chatCell a {
  color: #000;
  text-decoration: underline;
}
.chatCellMe {
  padding: 2px 4px;
  margin-right: 12px;
  min-width: 40px;
  max-width: 80%;
  background-color: #67a3a9;
  border-radius: 18px;
  margin-bottom: 8px;
  display: block;
  position: relative;
  float: right;
  margin-left: 10%;
  line-height: 100%;
}
.chatCellMe a {
  color: #fff;
  text-decoration: underline;
}
.chatAuthor {
  clear: all;
  text-align: left;
  display: block;
  margin: 0px;
  margin-left: 12px;
  margin-bottom: 5px;
  margin-top: -5px;
  padding: 0px;
  color: #666;
  font-size: 12px;
}
.chatAuthorMe {
  clear: all;
  text-align: right;
  display: block;
  margin: 0px;
  margin-right: 12px;
  margin-bottom: 5px;
  margin-top: -5px;
  padding: 0px;
  color: #666;
  font-size: 12px;
}
.chatName {
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 0;
  margin-left: 8px;
  margin-top: 4px;
}
.chatNameMe {
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  right: 0;
  margin-right: 8px;
  margin-top: 4px;
  color: #fff;
}
.chatTextMe {
  margin: 8px;
  text-align: left;
  color: #fff;
}
.chatText {
  margin: 8px;
  text-align: left;
}

.box-1:nth-child(even) {
  background: pink;
}
.chatList {
  border-left: solid 2px #fff;
  background: #fff;
}

.chatList.empty {
  background-image: url("./Assets/LyfSupportNewScreen.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #d5f3eb;
  background-position: center center;
}
@media only screen and (max-width: 992px) {
  .chatList.empty {
    background-image: url("./Assets/LyfSupportMobileScreen.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    background-color: #f3fdfa;
  }
}
.chatToolbar {
  border-right: solid 1px #ddd;
}

.cellHolder {
  background-color: #eee;
  background-image: url("./Assets/chat_background.jpg");
  background-size: cover;
  background-repeat: repeat;
  white-space: pre-line;
}

.cellHolderColor {
  background-color: #dbf8f1;
  white-space: pre-line;
}

.chatBox {
  text-align: left;
}
.chatBoxBorder {
  background: #fff;
  border-radius: 10px;
  border: solid 1px #ccc;
  padding: 0px;
  margin: 0px;
}
.chatBoxInput {
}
.chatBoxFiles {
  background: #eee;
  border: dashed 1px #ddd;
}
.chatBoxFiles p {
  margin: 0px;
  margin-left: 10px;
  margin-top: 0px;
  padding: 0px;
  font-size: 0.9em;
  line-height: 30px;
  color: #999;
  cursor: pointer;
}
.chatBoxFileScroller {
  position: relative;
  width: 50px;
  height: 50px;
  border: solid 1px #ddd;
  border-radius: 4px;
  overflow: hidden;
}
.chatBoxFileScroller img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.chatBoxFileScroller:hover img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
}
.chatBoxFileScroller a {
  position: absolute;

  top: 0;
  right: 0;
  width: 20px;
  height: 20px;

  color: red;

  opacity: 0;
  cursor: pointer;
  text-align: center;
}
.chatBoxFileScroller:hover a {
  opacity: 0.3;
}
.chatBoxFiles input:focus {
  outline: none !important;
}
.chatBox textarea {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0px;
  padding: 0px;
}
.chatBox textarea:focus {
  outline: none !important;
}
.chatBox button {
  width: 100px;
  height: 34px;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  border-radius: 8px;
}
.chatBox button:disabled {
  background: #ccc;
}
.chatBoxNote {
  margin: 0px;
  padding: 10px;
  overflow: auto;
}
.chatBoxNote strong {
  margin-right: 10px;
}

.singleAttachment {
  overflow: hidden;
}
.attachmentBanner {
  opacity: 0.99;
  margin-top: -64px;
  height: 64px;
  background-color: #ededed;
  width: 400px;
}
.attachmentTitle {
  left: 0;
  margin: 0px 8px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 8px);
}
.attachmentUrl {
  left: 0;
  bottom: -4px;
  margin: 0px 8px;
  font-size: 12px;
  opacity: 0.7;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 8px);
}
.attachments {
  row-gap: 5px;
  column-gap: 10px;
}
.chatImage {
  border-radius: 9px;
  object-fit: cover;
  cursor: pointer;
}

.chatHistory {
  position: absolute;
  top: 50px;
  right: 0px;
  height: 80%;
  width: 80%;
  background: #eee;
  z-index: 10000;
  border-bottom: solid 1px #ddd;
  border-left: solid 1px #ddd;
  border-top: solid 1px #ddd;
  border-bottom-left-radius: 18px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  padding: 10px;
}
.chatHistoryToolbar {
  position: absolute;
  top: 5px;
  left: 10px;
  right: 10px;
  height: 40px;
}
.chatHistoryToolbar div {
  float: right;
}
.chatHistoryToolbar a {
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
}
.chatHistoryList {
  position: absolute;
  top: 40px;
  bottom: 10px;
  left: 10px;
  width: 200px;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}
.chatHistoryNote {
  position: absolute;
  top: 40px;
  left: 211px;
  right: 10px;
  height: 100px;
  background: #e6e6e6;
  overflow-x: hidden;
  overflow-y: auto;
}
.chatHistoryNote p {
  margin: 0px;
  padding: 10px;
  font-size: 12px;
  color: #666;
}
.chatHistoryMessages {
  position: absolute;
  top: 140px;
  bottom: 10px;
  left: 211px;
  right: 10px;
  background: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
}
.chatHistorySession {
  padding: 10px;
  margin: 0px;
  border-bottom: solid 1px #ccc;
}
.chatHistorySession:hover {
  cursor: pointer;
  background: #eeeeee;
}
.chatHistorySession h4 {
  padding: 0px;
  margin: 0px;
  font-size: 14px;
}
.chatHistorySession p {
  padding: 10px 0px 0px 0px;
  margin: 0px;
  font-size: 14px;
}
.chatHistorySession.active {
  background: #eeeeee;
}

.typing {
  padding: 10px;
}

.tab_strip li {
  float: left;
  padding: 10px;
  border-right: solid 1px #ccc;
  height: 40px;
  background: #ddd;
  cursor: pointer;
}
.tab_strip li.active {
  background: #ecfbf7;
  cursor: default;
}
.tab_pane {
  display: none;
  overflow-y: auto;
}
.tab_pane.active {
  display: block;
}

.swal2-html-container {
  overflow: visible !important;
}
.swal2-actions {
  z-index: 0 !important;
}

.brightMint {
  color: #3d3d3d;
}

.deepTeal {
  color: #005b66;
}

.lato {
  font-family: "Lato", sans-serif;
}

.roboto {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}

.robotoThin {
  font-family: "Roboto", sans-serif;
  font-weight: 40;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.signInHolder {
  margin: 8em auto 0px auto;
  width: 500px;
}
.signInHolder form {
  text-align: left;
  width: 436px;
}
.signInHolder label {
  font-size: 16px;
  padding: 4px 0px;
  display: block;
}
.signInHolder input {
  width: 410px;
}

.signInPage {
  background-color: #d8f9f9f9;
  display: inline-block;
  margin-top: 0em;
  padding: 32px;
  border-radius: 16px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media print {
  .sharedsession {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  @page {
    size: A4 landscape;
    margin-left: 0;
    margin-right: 0;
    margin-top: 3.556mm;
    margin-bottom: 9.906mm;
  }
}

.fa-icon {
  position: absolute;
  top: 8px;
  left: 10px;
  color: #9ca3af;
}

.formInputIcon {
  text-indent: 23px;
}
